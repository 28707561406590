<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <div class="input-content-wrap">
        <v-row
          dense
          class="pb-0"
        >
          <v-col
            class="pb-0 pt-0"
            :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
            :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
          >
            <v-autocomplete
              id="input-pcode"
              ref="refPcode"
              v-model="selectedPCode"
              :items="productInfoList"
              label="품번"
              item-text="품번"
              item-value="품번"
              auto-select-first
              clearable
              @change="onPCodeChange"
              @keyup.enter.prevent="onEnterPcode"
            />
          </v-col>
          <v-col
            v-if="$store.getters.getMobileModel !== 'PDA'"
            class="pb-0 pt-4 pl-0"
            cols="1"
          >
            <v-icon
              @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
            >
              mdi-camera
            </v-icon>
          </v-col>
          <v-col
            class="pa-0"
            cols="4"
          >
            <v-dialog
              ref="dialog"
              v-model="modal"
              class="date-picker"
              :return-value.sync="selectedDate"
              :width="datePickerWidth"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  label="발생일자"
                  autocomplete="off"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedDate"
                locale="ko-ko"
                :min="limitDate"
                :max="today"
                @input="onDateInput"
              />
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col
            class="pb-0 pt-0"
            cols="6"
          >
            <v-select
              id="input-badType"
              v-model="selectedBadType"
              :items="badType"
              label="불량원인"
              item-text="코드명"
              item-value="코드"
              auto-select-first
              @change="onBadTypeChange"
            />
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="6"
          >
            <v-text-field
              id="input-badQty"
              v-model="badQty"
              autocomplete="off"
              clearable
              label="불량수량"
              placeholder="불량수량"
              type="number"
              @keyup.enter.prevent="onClickSave"
            />
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col
            cols="12"
          >
            <v-btn
              width="100%"
              color="primary"
              @click="onClickSave"
            >
              불량 내역 저장
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
          v-model="selectedPCode"
          label="품번"
          autocomplete="off"
          :readonly="true"
          filled
        />
        <v-text-field
          v-model="selectedGoodsName"
          label="품명"
          autocomplete="off"
          :readonly="true"
          filled
        />
      </div>
      <div style="margin-top:6px; background-color:black;">
        <dx-data-grid
          ref="refBadReceiptGrid"
          :data-source="badReceiptInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="seqId"
          :focused-row-key.sync="focusedRowKey"
          @focused-row-changed="onSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
          @row-removed="onRowRemoved"
        >
          <DxColumnFixing :enabled="true" />
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :fixed="false"
            :min-width="140"
            sort-order="asc"
          />
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="수량"
            :min-width="60"
            format="#,##0"
          />
          <DxColumn
            :allow-editing="false"
            caption="거래처"
            data-field="거래처명"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="품명"
            :min-width="100"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
          <template #grid-title>
            <div
              class="toolbar-label"
              style="font-size:1.1rem;margin-left:0px;"
            >
              <b>{{ selectedDate }} 불량품현황</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing, DxSummary, DxTotalItem, DxEditing } from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'BadReceipt',
  components: {
    Loading,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxDataGrid,
    DxColumnFixing,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  props: {
    goodsType: {
      type: String,
      default: 'S0100'
    }
  },
  data: function () {
    return {
      badType: null,
      badQty: null,
      datePickerWidth: '350px',
      selectedBadType: null,
      today: AppLib.getToday(),
      selectedDate: AppLib.getToday(),
      modal: false,
      거래처코드: null,
      editButtons: ['delete'],
      isLoading: false,
      userInfo: null,
      selectedPCode: null,
      productInfoList: [],
      selectedProductInfo: null,
      focusedRowKey: null,
      badReceiptInfo: null,
      gridHeightSub: null,
      selectedRow: null,
      selectedGoodsName: null,
      searchedPalletInfo: null,
      limitDate: null,
      isSaving: false,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.limitDate = `${AppLib.getToday().substring(0, 7)}-01 00:00:00`
    this.badType = this.$store.getters.getBaseDataByType(ConstDef.불량원인)
    this.refreshBadReceipt()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (this.goodsType === ConstDef.구매품) {
      this.productInfoList = this.$store.getters.getGoodsList(this.goodsType)
    } else {
      this.productInfoList = this.$store.getters.getGoodsList(ConstDef.완제품)
      this.productInfoList.push(...this.$store.getters.getGoodsList(ConstDef.반제품))
      this.productInfoList.push(...this.$store.getters.getGoodsList(ConstDef.다공정품))
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize (e) {
      const drawContentHeight = this.$Q('.input-content-wrap').height()
      this.gridHeightSub = this.$Q('html').height() - drawContentHeight - 76
    },
    initInput () {
      this.selectedPCode = null
      this.badQty = null
      this.selectedGoodsName = null
      this.selectedBadType = null
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
      }, 100)
    },
    getText (row) {
      return `${row.품번}`
    },
    onClickSave (e) {
      if (this.isSaving) return

      if (this.selectedPCode === null) {
        this.$snotify.error('품번을 선택 하여주세요')
        this.$Q('#input-pcode').focus()
        return
      }
      if (this.selectedBadType === null) {
        this.$snotify.error('불량원인을 선택하여 주세요')
        this.$Q('#input-badType').focus()
        return
      }
      if (this.badQty === null) {
        this.$snotify.error('불량수량을 입력하여 주세요')
        this.$Q('#input-badQty').focus()
        return
      }
      this.isSaving = true
      if (this.selectedProductInfo.품목유형 !== ConstDef.구매품) {
        this.거래처코드 = this.selectedProductInfo.납품처코드
      } else {
        this.거래처코드 = this.selectedProductInfo.거래처코드
      }
      let 폐기수량 = this.badQty
      let 반품수량 = 0
      if (this.goodsType === ConstDef.구매품) {
        폐기수량 = 0
        반품수량 = this.badQty
        this.badQty = 0
      }
      const param = [
        {
          입출고구분: ConstDef.불량입고,
          불량원인: this.selectedBadType,
          품번: this.selectedPCode,
          입고키: null,
          불량수량: this.badQty,
          반송수량: 0,
          재투입수량: 0,
          퍠기수량: 폐기수량,
          반품수량: 반품수량,
          거래처코드: this.거래처코드,
          장소위치코드: null,
          실적key: null
        }
      ]
      console.log(param)
      this.$_sp.runUpdateProc('spSaveBadProduct', param)
        .then((data) => {
          this.refreshBadReceipt()
          this.initInput()
          this.isSaving = false
        })
        .catch(error => {
          this.isSaving = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onBadTypeChange (e) {
      this.$Q('#input-badQty').focus()
    },
    // Date 관련
    onDateInput (e) {
      this.$refs.dialog.save(this.selectedDate)
      this.$Q('#input-badType').focus()
      this.refreshBadReceipt()
    },
    onEnterPcode (e, pCode) {
      let split = null
      if (pCode === undefined) {
        split = this.$Q('#input-pcode').val()
      } else {
        split = pCode
      }
      split = AppLib.base64DecodeSubstring(split).split('/')
      console.log(this.selectedPCode)
      this.selectedPCode = split[0].replace('?', ' ')
      console.log(this.selectedPCode)
      this.selectedProductInfo = this.$store.getters.getGoodsItemByGoodsNo(this.selectedPCode)
      if (this.selectedProductInfo === undefined) {
        this.$snotify.info('해당 품목을 검색할 수 없습니다.')
        this.initInput()
        return
      }
      this.selectedGoodsName = this.selectedProductInfo.품명
      setTimeout(() => {
        this.$Q('.v-autocomplete__content').css('display', 'none')
        this.$Q('#input-badType').focus()
      }, 10)
    },
    onPCodeChange (goodsNo) {
      this.onEnterPcode(null, goodsNo)
    },
    onPCodeScan (goodsNo) {
      this.onEnterPcode(null, goodsNo)
    },
    onRowRemoved (row) {
      this.$_sp.runUpdateProc('spDeleteBadProduct', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.', { timeout: 700 })
          this.initInput()
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onStateChange (e) {
      this.$Q('#input-division').focus()
    },
    onToolbarPreparing   (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'grid-title'
        }
      )
    },
    refreshBadReceipt () {
      const param = {
        발생일자: this.selectedDate,
        createid: this.userInfo.userId,
        입출고구분: ConstDef.불량입고
      }
      this.$_sp.runNoEncodeFindProc('spFindAllBadReceiptByDateAndRegIdAndType', param)
        .then((data) => {
          this.badReceiptInfo = this.$_sp.MakeModel(data)
          this.initInput()
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
